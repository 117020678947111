<script lang="ts" setup>
import { useUiStore } from '~/stores/ui'

const uiStore = useUiStore()
const social = ref([
  {
    name: 'Facebook',
    href: 'https://en-gb.facebook.com/butlersherborn/',
    icon: 'mdi:facebook',
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/butlersherborn/',
    icon: 'mdi:instagram',
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/butler-sherborn',
    icon: 'mdi:linkedin',
  },
])
</script>

<template>
  <div>
    <div
      class="w-full h-full hidden lg:grid lg:grid-cols-7 text-lg xl:text-xl"
    >
      <div class="left-part col-span-1 h-full flex gap-4 xl:gap-5 items-center">
        <LogoMain class="shrink-0" />
      </div>

      <div class="right-part col-span-6 h-full flex gap-4 lg:gap-4 xl:gap-8 items-center justify-end lg:mt-0.5 xl:mt-1">
        <HeaderMenuDropDownBuy />
        <HeaderMenuDropDownSell />
        <HeaderMenuDropDownAbout />
        <nuxt-link to="/articles" class="font-semibold hover:opacity-80">
          News & Comment
        </nuxt-link>
        <nuxt-link to="/contact" class="font-semibold hover:opacity-80">
          Contact
        </nuxt-link>
        <div class="flex space-x-3 mb-1">
          <nuxt-link
            v-for="item in social"
            :key="item.name"
            :href="item.href"
            class="block"
          >
            <span class="sr-only">{{ item.name }}</span>
            <Icon :name="item.icon" class="h-5 w-5" aria-hidden="true" />
          </nuxt-link>
        </div>
      </div>
    </div>

    <!-- Mobile menu button -->
    <div class="justify-between items-center w-full h-full flex lg:hidden">
      <LogoMain class="shrink-0" />
      <div class="focus:outline-none">
        <button @click="uiStore.showMobileMenu()">
          <span class="sr-only">Open menu</span>
          <Icon name="heroicons:bars-3-bottom-right" class="text-4xl" aria-hidden="true" />
        </button>
      </div>
    </div>
  </div>
</template>
