<script lang="ts" setup>
import { useWindowScroll } from '@vueuse/core'

defineProps({
  mode: {
    type: String as PropType<string>,
    default: 'invert-mode',
  },
  shrink: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  lazy: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})

const { y } = useWindowScroll()

const scrollDirection: Ref<null | 'up' | 'down'> = ref(null)
const offset = 112

watch(y, (newY, oldY) => {
  if (newY > oldY) {
    scrollDirection.value = 'down'
  }
  else {
    scrollDirection.value = 'up'
  }
})

const showMenu: ComputedRef<'fixed' | 'sticky' | 'hidden'> = computed(() => {
  // return either 'fixed', 'sticky' or 'hidden'
  if (y.value < offset) {
    return 'fixed'
  }
  if ((y.value > offset) && scrollDirection.value === 'up') {
    return 'sticky'
  }
  return 'hidden'
})

const nowHidden = ref(true)

// watch showMenu
watch(showMenu, (newVal, oldVal) => {
  if (oldVal === 'fixed' && newVal === 'hidden') {
    // set nowHidden to true in 500ms
    setTimeout(() => {
      nowHidden.value = true
    }, 500)
  }

  if (oldVal === 'hidden' && newVal === 'sticky') {
    nowHidden.value = false
  }

  if (oldVal === 'sticky' && newVal === 'fixed') {
    nowHidden.value = false
  }
})
</script>

<template>
  <ClientOnly>
    <nuxt-link to="/">
      <nuxt-img
        v-if="mode === 'invert-mode'"
        src="/logo.png"
        alt="Butler Sherborn logo"
        :width="179"
        :height="93"
        class="logo object-contain w-auto h-10 lg:h-16 xl:h-20 hover:opacity-80 transition duration-500 ease-in-out"
        :class="{
          'logo-is-fixed': showMenu === 'fixed',
          'logo-is-sticky': showMenu === 'sticky',
          'logo-is-hidden': (showMenu !== 'fixed' || 'sticky') && nowHidden,
        }"
        loading="eager"
      />
      <nuxt-img
        v-else
        src="/logo-white.png"
        alt="Butler Sherborn logo"
        :width="179"
        :height="93"
        class="object-contain w-auto h-10 lg:h-16 xl:h-20 hover:opacity-80 transition duration-500 ease-in-out"
        :class="[
          shrink ? 'w-auto h-10 lg:h-16 xl:h-20' : 'h-16',
        ]"
        loading="eager"
      />
    </nuxt-link>
  </ClientOnly>
</template>
