<script setup lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

const popoverHover: Ref<any> = ref(false)
const popoverTimeout: Ref<any> = ref(null)

const hoverPopover = (e: { target: { click: () => void } }, open: any) => {
  popoverHover.value = true
  if (!open && typeof e.target.click === 'function') {
    e.target.click()
  }
}

const closePopover = (close: () => void) => {
  popoverHover.value = false
  if (popoverTimeout.value) {
    clearTimeout(popoverHover.value)
  }
  popoverTimeout.value = setTimeout(() => {
    if (!popoverHover.value)
      close()
  }, 3)
}

const menu = [
  {
    label: 'Sell Your Property',
    href: '/property-services/sell',
    description: 'Maximize your property\'s value with our expert marketing.',
    icon: 'fluent:real-estate-24-filled',
  },
  {
    label: 'Let Your Property',
    href: '/property-services/landlords',
    description: 'Bespoke property management solutions.',
    icon: 'material-symbols:key-outline-rounded',
  },
  // {
  //   label: 'Selling Country Homes',
  //   href: '/sell/country',
  //   description: 'Expert representation for unique country properties.',
  //   icon: 'game-icons:hills',
  // },
  {
    label: 'Selling Land & Farms',
    href: '/property-services/land-farms',
    description: 'Fertile grounds for rural investment.',
    icon: 'lucide:wheat',
  },
  {
    label: 'Selling Equestrian Property',
    href: '/property-services/equestrian-property',
    description: 'Tailored marketing from inside the equestrian world.',
    icon: 'mdi:horse-variant',
  },
]

const featured = ref([
  {
    name: 'Instant Digital Valuation',
    href: '/digital-valuation',
    imageSrc: '/img/features/digital-valuation.png',
    lqip: '/img/features/digital-valuation.lqip.webp',
    imageAlt: 'A cotswolds property with a beatuiful garden.',
  },
  {
    name: 'Why Butler Sherborn?',
    href: '/why-butler-sherborn',
    imageSrc: '/img/features/why-butler-sherborn.png',
    imageAlt: 'Cotwolds stone wall and parklands with a house in the distance.',
    lqip: '/img/features/why-butler-sherborn.lqip.webp',
  },
  {
    name: 'Testimonials',
    href: '/success-stories',
    imageSrc: '/img/features/success-stories.png',
    imageAlt: 'A Cotwolds property successfully sold by Butler Sherborn.',
    lqip: '/img/features/success-stories.lqip.webp',
  },
])
</script>

<template>
  <div class="relative flex h-full">
    <Popover
      v-slot="{ open, close }"
      class="relative isolate z-50 flex"
    >
      <div class="flex">
        <PopoverButton
          id="sell-menu"
          class="font-semibold h-full hover:opacity-80 flex items-center gap-x-1 leading-6 outline-none focus:outline-none"
          @mouseover="(e) => hoverPopover(e, open)"
          @mouseleave="closePopover(close)"
        >
          Market Your Property
          <Icon name="mdi:chevron-down" class="h-5 w-5" aria-hidden="true" />
        </PopoverButton>
      </div>

      <transition
        enter-active-class="transition ease-out duration-200"
        enter-from-class="opacity-0 -translate-y-1"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="transition ease-in duration-150"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 -translate-y-1"
      >
        <PopoverPanel
          v-slot="{ close }"
          class="fixed left-0 z-10 mt-16 flex w-screen"
        >
          <div
            class="max-w-8xl mx-auto px-4 py-8"
            @mouseover.prevent="popoverHover = true"
            @mouseleave="closePopover(close)"
          >
            <div class="bg-white shadow-lg rounded-lg overflow-hidden ring-1 ring-gray-200/30 text-gray-700">
              <div class="mx-auto grid max-w-8xl grid-cols-7 gap-y-12">
                <!-- Column 1 -->
                <div class="grid col-span-4 grid-cols-2">
                  <!-- Column 1B -->
                  <div class="p-8 bg-stone-100 border-r border-stone-200">
                    <h3
                      class="text-2xl font-semibold leading-6 tracking-tight"
                    >
                      Cotswolds Property Services
                    </h3>
                    <nuxt-link
                      v-for="item in menu"
                      :key="item.label"
                      :href="item.href"
                      class="flex items-start gap-2 hover:bg-stone-50 p-2 rounded-md my-3"
                      @click="close"
                    >
                      <Icon :name="item.icon" class="mt-1 text-lg text-forest-600 flex-none" aria-hidden="true" />
                      <div
                        class="text-base font-semibold text-forest-600"
                        @click="close"
                      >
                        {{ item.label }}
                        <p class="mt-1 font-normal text-sm text-gray-700 mb-0">
                          {{ item.description }}
                        </p>
                      </div>
                    </nuxt-link>
                  </div>
                  <!-- Column 1A -->
                  <div class="">
                    <div class="sell p-8">
                      <h4 class="text-2xl font-semibold mb-2">
                        Sell Your Property
                      </h4>
                      <p class="text-sm text-gray-700">
                        With a track record of maximising property values, swift transactions, and delighted clients, we'll find the right buyer for your property.
                      </p>
                      <UiBtn
                        variant="solid"
                        size="md"
                        color="forest"
                        to="/property-services/sell"
                        label="Market Appraisal"
                        :padded="true"
                        class="mb-3 font-semibold"
                      />
                      <ul class="text-xs text-gray-700 list-disc list ml-4 sr-only">
                        <li>Reliable valuation from the Cotswolds specialists</li>
                        <li>Expert marketing to maximise your property's value</li>
                      </ul>
                    </div>
                    <div class="let px-8">
                      <h4 class="text-2xl font-semibold mb-2">
                        Let Your Property
                      </h4>
                      <p class="text-sm text-gray-700">
                        Butler Sherborn’s residential lettings department is one of the most active in The Cotswolds. We'll help you find the right tenant for your property.
                      </p>
                      <UiBtn
                        variant="link"
                        size="sm"
                        color="forest"
                        to="/property-services/landlords"
                        label="Lettings Appraisal"
                        :padded="false"
                        class="mb-3 font-semibold"
                      />
                    </div>
                  </div>
                </div>
                <!-- Column 2 -->
                <div class="p-8 col-span-3">
                  <div class="grid grid-cols-2 grid-rows-1 gap-8 text-sm">
                    <HeaderMenuDropDownPromotedSuccessStory />

                    <div v-for="(item) in featured" :key="item.name" class="group aspect-h-2 aspect-w-3 overflow-hidden rounded-md bg-gray-100 col-span-1 relative">
                      <nuxt-img
                        v-if="item.imageSrc"
                        :src="item.imageSrc"
                        quality="80"
                        :alt="item.imageAlt"
                        class="object-cover object-center group-hover:opacity-75"
                        :width="260"
                        :height="174"
                        preload
                        loading="eager"
                        :placeholder="item.lqip"
                      />
                      <div v-else class="bg-gradient-to-t from-forest-700 to-forest-500" />
                      <div v-if="item.imageSrc" class="absolute opacity-20 bg-black inset-0" />
                      <div v-if="item.imageSrc" class="absolute bg-gradient-to-t from-black via-transparent to-transparent inset-0" />
                      <div class="bg-transparent p-4 text-base flex flex-col justify-end">
                        <UiBtn
                          :to="item.href"
                          class="text-white"
                          :padded="false"
                          variant="link"
                          color="white"
                          size="lg"
                        >
                          <span class="absolute inset-0" aria-hidden="true" />
                          {{ item.name }}
                        </UiBtn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>
